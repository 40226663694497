body {
  background-color: #f2dcbb;
  /* background-image: -webkit-linear-gradient(#f2dcbb,#d8d7b3 ); */
  margin-bottom: 60px;
}

.main-container {
  min-height: 100%;
  height: 100%;
}

.xp-info {
  height: 20;
  width: 100%;
  margin: 50;
}

.progress {
  margin: 5px;
  height: 20px;
  width: 100%;
}
.progress-bar {
  background-color: #892cdc;
}
/* .progress-bar {
  margin: 5px;

  background-color: #bc6ff1;
  font-weight: 500;
  color: #f9f7cf;
  font-size: small;
  height: 100%;
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
} */

/* .main-container {
    flex: 1 0 auto;
    padding: var(--space) var(--space) 0;
    width: 100%;
} */
/* 
.main-container:after {
    content: '\00a0';
    display: block;
    margin-top: var(--space);
    height: 0;
    visibility: hidden;
} */

/* .main-container {
    padding-bottom: 2px;
} */

.color-nav {
  background-color: #f2dcbb;
  color: white;
}

#nav-bar .nav-link {
  color: black !important;
}

#nav-bar .nav-link:hover {
  color: #8785a2 !important;
}

.btn-primary {
  background-color: #892cdc;
}

.btn-secondary {
  background-color: #8785a2;
}

.footer {
  background-color: #f2dcbb;

  margin: 3rem 1rem auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  /* position: absolute;
  left: 0;
  bottom: 0;
  right: 0; */
}
